import * as Sentry from "@sentry/sveltekit";

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://671fe9004b24b2df056d2037e263ee71@o4506464630603776.ingest.us.sentry.io/4506464631193600",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    integrations: []
})

export const handleError = Sentry.handleErrorWithSentry();